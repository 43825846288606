import React, {
  FC,
  FormEvent,
  MutableRefObject,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from "react"
import type { TokenPayload } from "@recurly/recurly-js"
import {
  CardElement,
  Elements,
  RecurlyProvider,
  useRecurly,
  UseRecurlyInstance,
} from "@recurly/react-recurly"
import { useCountry, useSelectedPrice, useUserId } from "~/store/selectors"
import { VFlex } from "~/components"

import { useOnPaymentStatusChange } from "./useOnPaymentStatusChange"
import { CONFIG } from "~/config"
import { recurlyBindUser, recurlyMakePurchase } from "~/api/api"

const handleBlur = () => console.log("[blur]")
const handleChange = (change: unknown) => console.log("[change]", change)
const handleFocus = () => console.log("[focus]")
const handleReady = () => console.log("[ready]")
const handleSubmit = () => console.log("[submit]")

const getToken = (
  recurly: UseRecurlyInstance,
  formRef: MutableRefObject<null>
): Promise<TokenPayload> => {
  const p = new Promise<TokenPayload>((resolve, reject) => {
    if (formRef.current === null) {
      return Promise.reject("Form is not initialized")
    }
    recurly.token(formRef.current, (err: unknown, token: TokenPayload) => {
      if (err) {
        reject(err)
      } else {
        resolve(token)
      }
    })
    return
  })
  return p
}

const CardForm = () => {
  const recurly = useRecurly()
  const formRef = useRef(null)
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false)
  const [errorResp, setErrorResp] = useState("")

  const selectedPrice = useSelectedPrice()

  const onPaymentComplete = useOnPaymentStatusChange()

  const userId = useUserId()
  const countryCode = useCountry()

  const handleSubmitForm = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      if (event.preventDefault) event.preventDefault()
      if (formRef.current) {
        setIsFormLoading(true)
        try {
          const token = await getToken(recurly, formRef)
          const bindStatus = await recurlyBindUser({ userId, token: token.id })
          console.debug("bindStatus", bindStatus)
          const priceId = selectedPrice?.payment_providers?.recurly?.plan_id ?? "UNKNOWN_PLAN_ID"
          const currency = selectedPrice?.currency_code ?? "USD"
          const purchaseStatus = await recurlyMakePurchase({ userId, priceId, currency })
          console.debug("purchaseStatus", purchaseStatus)
          if (purchaseStatus.status === "success") {
            onPaymentComplete("RECURLY", "COMPLETE")
          } else {
            onPaymentComplete("RECURLY", "FAIL")
          }
        } catch (error) {
          setErrorResp(String(error))
          onPaymentComplete("RECURLY", "ERROR")
        } finally {
          setIsFormLoading(false)
        }
      }
    },
    [selectedPrice, userId, recurly, formRef, onPaymentComplete]
  )

  return (
    <form onSubmit={handleSubmitForm} ref={formRef}>
      {isFormLoading ? <div>Loading...</div> : null}
      {errorResp ? <div>{errorResp}</div> : null}
      <div>
        <input data-recurly="first_name" placeholder="First Name" defaultValue="John" />
        <input data-recurly="last_name" placeholder="Last Name" defaultValue="Smith" />
        <input data-recurly="postal_code" placeholder="Postal Code" defaultValue="94117" />
        <input data-recurly="address1" placeholder="address1" defaultValue="Limassol street" />
        <input data-recurly="city" placeholder="city" defaultValue="Limassol" />
        <select data-recurly="country" placeholder="Country" defaultValue={countryCode}>
          <option value="CY">Cyprus</option>
          <option value="GB">Great Britain</option>
          <option value="US">USA</option>
        </select>
      </div>
      <CardElement
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        onReady={handleReady}
        onSubmit={handleSubmit}
      />
      <div>
        <button type="submit" disabled={isFormLoading}>
          Pay
        </button>
      </div>
    </form>
  )
}

const RecurlyPaymentForm: FC = () => (
  <RecurlyProvider publicKey={CONFIG.recurly.publicKey}>
    <Elements>
      <CardForm />
    </Elements>
  </RecurlyProvider>
)

export const PaymentFormRecurlyPopupContainer: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <VFlex w="full" overflow="hidden">
      {/*
      <PaymentProviderSwitch
        onChange={onChangePaymentProvider}
        selected={selectedProvider}
        isDisabled={isFormLoading}
        providers={PaltaProviders}
        height="64px"
      />
 */}
      <VFlex w="full" gap={4} pt="26px">
        {children}

        <RecurlyPaymentForm />
      </VFlex>
    </VFlex>
  )
}
