import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Box, Button, Flex, Heading, useBoolean } from "@chakra-ui/react"

import { VFlex } from "~/components/VFlex"
import { useUpsaleNewSubscriptionsData } from "~/store/selectors"

import { Step2Modal } from "./Step2Modal"
import { WorkbookHeader, WorkbookCard, PriceInfo, DiscountShield } from "./WorkbookCard"
import { makePaltaPurchase } from "~/api/api"
import { useAuthInfo } from "~/utils/useAuthInfo"
import { useNavigateSuccess } from "../NavigateSuccessLink"
import { useAmplitude } from "~/utils/analytics/useAmplitude"
import { PriceContentV2_PriceItem } from "~/generated/paywall"
import { Navigation } from "./Navigation"
import { TimerNoticeContainer } from "~/pages/Main/PriceSelectWithPaymentForm"
import { NewSubscriptionsPrices } from "~/pages/Processing/UpsaleNewSubscriptions/NewSubscriptionsPrices"
import { ReactComponent as CheckIcon } from "./assets/check_icon.svg"
import { ReactComponent as ShieldIcon } from "./assets/shield.svg"
import { formatPrice, formatUnitWithPrefixLength } from "~/utils"
import { UpsaleContext } from "~/pages/Processing/context"
import {
  AlternativeVariant,
  AlternativeVariantProps,
} from "~/pages/AppLinkPage/AlternativeVariantProps"

type Step = 0 | 1 | 2
export const UpsaleNewSubscriptions: FC<{
  step: Step
  isLoading: boolean
  prices: PriceContentV2_PriceItem[]
  discountedPrices: PriceContentV2_PriceItem[]
  onClickPay: () => void
  onClickNext: () => void
  selectedPriceId: string
  selectedPrice?: PriceContentV2_PriceItem
  setSelectedPriceId: (price: string) => void
  workbookPrice: PriceContentV2_PriceItem
}> = ({
  step,
  onClickPay,
  onClickNext,
  isLoading = false,
  prices,
  discountedPrices,
  selectedPriceId,
  selectedPrice,
  setSelectedPriceId,
  workbookPrice,
}) => {
  useEffect(() => {
    if (step == 2) {
      document.querySelector("#discount-card")?.scrollIntoView()
    }
  }, [step])

  const formatPrices = useCallback(
    (prices: PriceContentV2_PriceItem[]) => {
      return prices.map((price, index) => {
        return {
          priceItem: price,
          selected: selectedPriceId === price.id,
          isAssistant: index <= 1,
          isScanner: index === 0 || index === 2,
          workbook: true,
          onClick: () => setSelectedPriceId(price.id),
          workbookPrice,
        }
      })
    },
    [selectedPriceId, setSelectedPriceId, workbookPrice]
  )

  const newSubsPrices = useMemo(() => {
    return formatPrices(prices)
  }, [formatPrices, prices])

  const newSubsPricesDiscounted = useMemo(() => {
    return formatPrices(discountedPrices)
  }, [discountedPrices, formatPrices])

  const currentPrices = step === 0 || step === 1 ? newSubsPrices : newSubsPricesDiscounted

  return (
    <>
      <VFlex mt={4} paddingBottom="160px" paddingX={6}>
        <Navigation onClickSkip={onClickNext} />
        <Flex mt={8} alignItems="center">
          {step === 2 ? (
            <>
              <Box mr={3} pos="relative" marginBottom="-9px">
                <ShieldIcon />
                <DiscountShield top="-8px">
                  <div>UP TO</div>
                  <div>{selectedPrice?.subscription_text || "25"}%</div>
                  <div>OFF</div>
                </DiscountShield>
              </Box>
              <Heading as="h1" size="Header/MonospacedSecondary">
                Additional discount applied just for you
              </Heading>
            </>
          ) : (
            <Heading as="h1" size="Header/MonospacedSecondary">
              Exclusive Sign-Up Offers
            </Heading>
          )}
        </Flex>

        <TimerNoticeContainer mt={4} variant={"1"} />
        <Box w="full" mt={4}>
          <NewSubscriptionsPrices prices={currentPrices} />
        </Box>
        <Button isLoading={isLoading} onClick={onClickPay} mt={6} variant="action" w="full">
          Add to My Plan
        </Button>
        <Button
          disabled={isLoading}
          onClick={onClickNext}
          mt={1.5}
          textStyle="Subtitle/Primary"
          color="Base/baseSecondary"
          variant="secondary"
          w="full"
        >
          Skip this and continue enrollment
        </Button>
        <VFlex gap={1.5} mt={6}>
          {[
            "Professional workout programs",
            "Personalized palns to boost your results",
            "Auto renewal plans, cancel anytime",
          ].map((text, i) => {
            return (
              <Flex key={i} alignItems="center">
                <Flex mr={1.5}>
                  <CheckIcon width="16px" height="16px" />
                </Flex>
                <Flex textStyle="Paragraph/Secondary">{text}</Flex>
              </Flex>
            )
          })}
        </VFlex>
        <Box mt={6}>
          <WorkbookCard>
            <PriceInfo price={workbookPrice} />
          </WorkbookCard>
        </Box>
        {selectedPrice && (
          <Box textStyle="Paragraph/Tertiary" color="Base/baseSecondary" mt={6}>
            PLEASE NOTE: In addition to your subscription, your account will be charged{" "}
            {formatPrice(selectedPrice.currency_code, parseFloat(selectedPrice.economy_price))}] for
            [
            {formatUnitWithPrefixLength(
              selectedPrice.subscription_period_unit,
              selectedPrice.subscription_period_length
            )}
            ] for the selected add-ons when you click ADD TO MY PLAN. Payment will be processed
            automatically based on the billing information you provided earlier. You can cancel the
            subscription by reaching out to support at care@lovi.care. Please note that this service
            is bound by Lóvi’s Privacy Policy, Terms of Use, and Refund Policy.
          </Box>
        )}
      </VFlex>
      <Step2Modal
        isOpen={step === 1}
        onClose={onClickNext}
        workbookPrice={workbookPrice}
        additionalDiscount={selectedPrice?.subscription_text || "25%"}
      />
    </>
  )
}

export const UpsaleNewSubscriptionsContainer: FC = () => {
  const { goNext } = useContext(UpsaleContext)
  const [paymentPageVariant, setPaymentPageVariant] = useState<AlternativeVariantProps["variant"]>()

  const { user } = useAuthInfo()
  const { userId } = user ?? {}
  const upsaleNewSubsData = useUpsaleNewSubscriptionsData()

  const [isLoading, setLoading] = useBoolean()
  const [step, setStep] = useState<Step>(0)
  const findDefaultPriceId = useCallback((prices: PriceContentV2_PriceItem[]): string => {
    return prices.find((p) => p.default)?.id || prices[0]?.id || "error"
  }, [])
  const [selectedPriceId, _setSelectedPriceId] = useState(() =>
    findDefaultPriceId(upsaleNewSubsData?.prices || [])
  )
  const setSelectedPriceId = useCallback(
    (newSelectedPriceId: string) => {
      if (isLoading) {
        return
      }
      _setSelectedPriceId(newSelectedPriceId)
    },
    [isLoading]
  )
  const selectedPrice = useMemo(() => {
    return [
      ...(upsaleNewSubsData?.prices || []),
      ...(upsaleNewSubsData?.discounted_prices || []),
    ].find((p) => p.id === selectedPriceId)
  }, [selectedPriceId, upsaleNewSubsData?.discounted_prices, upsaleNewSubsData?.prices])

  const log = useAmplitude()
  useEffect(() => {
    log.upsaleNewSubscriptionView({ step })
  }, [log, step])

  useEffect(() => {
    document.querySelector("html")?.scrollTo(0, 0)
  }, [])

  const onFail = useCallback(() => {
    log.upsaleNewSubscriptionBuyFail()
    setPaymentPageVariant("bundleFail")
  }, [log, setPaymentPageVariant])

  const onSuccess = useCallback(() => {
    log.upsaleNewSubscriptionBuySuccess()
    setPaymentPageVariant("bundleSuccess")
  }, [log, setPaymentPageVariant])

  const onNext = useCallback(() => {
    if (step === 0) {
      setStep(1)
      return
    }
    if (step === 1) {
      if (upsaleNewSubsData?.discounted_prices) {
        setSelectedPriceId(findDefaultPriceId(upsaleNewSubsData.discounted_prices))
      }
      setStep(2)
      return
    }
    if (step === 2) {
      log.upsaleNewSubscriptionBuyExit()
      onFail()
      return
    }
  }, [
    step,
    upsaleNewSubsData?.discounted_prices,
    setSelectedPriceId,
    findDefaultPriceId,
    log,
    onFail,
  ])

  const onPay = useCallback(async (): Promise<boolean> => {
    // const selectedPrice = step === 0 ? price : discountPrice
    /* TODO: remove hardcoded payment_providers */
    const priceId = selectedPriceId
    try {
      if (!userId) {
        throw new Error("Unknown UserId")
      }
      if (!priceId) {
        throw new Error("Unknown PriceId")
      }
      setLoading.on()
      log.upsaleNewSubscriptionBuy({ priceId })
      const result = await makePaltaPurchase({ priceId, userId })
      if (result) {
        setLoading.off()
        onSuccess()
        return true
      }
    } catch (error) {
      log.upsaleNewSubscriptionBuyFail()
      onFail()
    }
    return false
  }, [log, onFail, onSuccess, selectedPriceId, setLoading, userId])

  return (
    <>
      {!paymentPageVariant && (
        <UpsaleNewSubscriptions
          step={step}
          isLoading={isLoading}
          onClickNext={onNext}
          onClickPay={onPay}
          prices={upsaleNewSubsData?.prices || []}
          discountedPrices={upsaleNewSubsData?.discounted_prices || []}
          selectedPriceId={selectedPriceId}
          selectedPrice={selectedPrice}
          setSelectedPriceId={setSelectedPriceId}
          workbookPrice={upsaleNewSubsData?.workbook_price || ({} as PriceContentV2_PriceItem)}
        />
      )}
      {paymentPageVariant && <AlternativeVariant variant={paymentPageVariant} onClick={goNext} />}
    </>
  )
}
