import React, { FC } from "react"
import { Heading } from "@chakra-ui/react"
import { noop } from "~/utils"
import { useBlockProperties, useSelectedPrice } from "~/store/selectors"
import type { PriceContentV2_PriceItem } from "~/generated/paywall"
import { Invoice3 } from "~/pages/Main/Invoice"
import {
  PaymentFormPaltaPopupContainer,
  PaymentFormRecurlyPopupContainer,
} from "~/pages/Main/PaymentForm"
import { SubscriptionTextContainer, TextBox3 } from "~/components/StaticTexts"

import { ModalHiddenWrapper } from "./ModalHiddenWrapper"
import { usePaymentFormPopupDisclosure } from "./usePaymentFormPopupDisclosure"

export const PaymentFormRecurlyWithInvoice3InPopup: FC<{
  hidden?: boolean
  onClose?: () => void
  selectedPrice: PriceContentV2_PriceItem
}> = ({ selectedPrice, hidden = false, onClose = noop }) => {
  return (
    <ModalHiddenWrapper hidden={hidden} onClose={onClose}>
      <Heading size="Header/Secondary" marginBottom={6} marginTop="-10px">
        Select a payment method
      </Heading>
      <PaymentFormRecurlyPopupContainer>
        <Invoice3 price={selectedPrice} />
        <TextBox3 bgColor="white" borderWidth="1px" borderColor="Base/neutralSecondary">
          <SubscriptionTextContainer />
        </TextBox3>
      </PaymentFormRecurlyPopupContainer>
    </ModalHiddenWrapper>
  )
}

export const PaymentFormWithInvoice3InPopup: FC<{
  hidden?: boolean
  onClose?: () => void
  selectedPrice: PriceContentV2_PriceItem
}> = ({ selectedPrice, hidden = false, onClose = noop }) => {
  return (
    <ModalHiddenWrapper hidden={hidden} onClose={onClose}>
      <Heading size="Header/Secondary" marginBottom={6} marginTop="-10px">
        Select a payment method
      </Heading>
      <PaymentFormPaltaPopupContainer>
        <Invoice3 price={selectedPrice} />
        <TextBox3 bgColor="white" borderWidth="1px" borderColor="Base/neutralSecondary">
          <SubscriptionTextContainer />
        </TextBox3>
      </PaymentFormPaltaPopupContainer>
    </ModalHiddenWrapper>
  )
}

export const PaymentFormWithInvoice3InPopupBlock: FC = () => {
  const selectedPrice = useSelectedPrice()
  const { isOpen, close } = usePaymentFormPopupDisclosure()
  const blockProperties = useBlockProperties()
  const recurlyEnabled = blockProperties["PB_PAYMENT_FORM_WITH_INVOICE_3_IN_POPUP.recurly_enabled"]
  if (selectedPrice) {
    if (recurlyEnabled) {
      return (
        <PaymentFormRecurlyWithInvoice3InPopup
          hidden={!isOpen}
          onClose={close}
          selectedPrice={selectedPrice}
        />
      )
    }
    return (
      <PaymentFormWithInvoice3InPopup
        hidden={!isOpen}
        onClose={close}
        selectedPrice={selectedPrice}
      />
    )
  }
  return null
}
