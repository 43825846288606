import { callOnce } from "~/utils/callOnce"
import { getSHA256Hex } from "~/utils/hashString"

type PriceInfo = { priceId: string; currency: string; value: number | string; userEmail: string } // FIXME value must be number
const PORA_PRODUCT = "pora_premium"

const getTtq = () =>
  window?.ttq ?? {
    page: () => undefined,
    identify: () => undefined,
    track: () => undefined,
  }
// We need to call initiateCheckout only once per session, otherwise
// algorithm will think that people who open checkout multiple times are 'better'
// but there are not
// So we save information that checkout called once in closure and thats why we use only one instance here
const pixelService = createTiktokPixelService(getTtq)

export const useTiktokPixel = () => {
  return pixelService
}

function createTiktokPixelService(getTtq: () => typeof window.ttq) {
  return {
    initiateCheckout: callOnce(({ currency, value }: PriceInfo) => {
      getTtq().track("InitiateCheckout", {
        content_id: PORA_PRODUCT,
        content_type: "product",
        content_name: PORA_PRODUCT,
        quantity: 1,
        value: value,
        price: value,
        currency,
      })
    }),
    purchase: async ({ currency, value, userEmail }: PriceInfo) => {
      const event_name = "CompletePayment"
      getTtq().track(event_name, {
        event_id: await getSHA256Hex(userEmail.toLowerCase() + "|" + event_name),
        email: await getSHA256Hex(userEmail.toLowerCase()),
        content_id: PORA_PRODUCT,
        content_type: "product",
        content_name: PORA_PRODUCT,
        quantity: 1,
        value: value,
        price: value,
        currency,
      })
    },
  }
}
