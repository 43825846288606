import { TypedUseSelectorHook, useSelector as useReduxSelector } from "react-redux"
import { AttributedStringType, toAttributedString } from "~/components"
import {
  SkinIssues,
  type HeaderGT2Properties,
  type HeaderGTProperties,
  type HeaderPrePaywallProperties,
  type PriceContentV2_PriceItem,
  type PriceSelectProperties,
  type WellnessProfile,
  WellnessProfile_WellnessLevel,
  PaywallBlocks,
  EnhancedPhotoProperties_EnhancementStatus,
} from "~/generated/paywall"
import type { RootState, State } from "../store"

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector

export const DEFAULT_DEEPLINK = "https://app.pora.ai/vqqJ/g5lgpl52"

export const useCountry = () => useSelector((state) => state.country ?? "UNKNOWN_COUNTRY")
const selectorPaymentInfo = (state: RootState) => state.payment
export const usePaymentInfo = () => useSelector(selectorPaymentInfo)
export const useDeepLink = () =>
  useSelector((state) => state.data?.links?.pora_app_deeplink ?? DEFAULT_DEEPLINK)
export const useUserEmail = (def = "UNKNOWN_EMAIL") =>
  useSelector((state) => state.data?.user?.email ?? def)
export const useUserId = () =>
  useSelector((state) => state.data?.user?.user_id ?? "UNKNOWN_USER_ID")

const upsaleSubscriptionOnExitIsActive = (state: RootState) => state.subscriptionOnExitFlow.active
const upsaleSubscriptionOnExitPrices = (state: RootState) =>
  state.data?.properties?.upsale_subscription_on_exit_properties?.prices_discounted ?? []

const pricesSelector = (state: RootState) =>
  upsaleSubscriptionOnExitIsActive(state)
    ? upsaleSubscriptionOnExitPrices(state)
    : state.data?.price?.priceses ?? []

export const usePrices = () => useSelector(pricesSelector)

const selectorUpsaleProps = (state: RootState) =>
  state.data?.properties?.upsale_subscription_properties

const selectorUpsaleSubscriptionPrice = (state: RootState) =>
  selectorUpsaleProps(state)?.upsale_price
export const useUpsaleSubscriptionPrice = () => useSelector(selectorUpsaleSubscriptionPrice)

const selectorUpsaleSubscriptionDiscountedPrice = (state: RootState) =>
  selectorUpsaleProps(state)?.upsale_price_discounted
export const useUpsaleSubscriptionDiscountedPrice = () =>
  useSelector(selectorUpsaleSubscriptionDiscountedPrice)

const selectorIsBlockExist = (state: RootState, block: PaywallBlocks) =>
  state.data?.paywall_blocks.includes(block) ?? false

const selectorUpsaleWorkbookData = (state: RootState) =>
  state.data?.properties?.upsale_workbook_properties ?? {
    price: undefined,
    price_discounted: undefined,
  }
export const useUpsaleWorkbookData = () => useSelector(selectorUpsaleWorkbookData)

const selectorUpsaleAIScannerData = (state: RootState) =>
  state.data?.properties?.upsale_ai_scanner_properties ?? {
    price: undefined,
    price_discounted: undefined,
  }

export const useUpsaleAIScannerData = () => useSelector(selectorUpsaleAIScannerData)

const selectorUpsaleNewSubscriptionsData = (state: RootState) =>
  state.data?.properties?.upsale_new_subscriptions_properties
export const useUpsaleNewSubscriptionsData = () => useSelector(selectorUpsaleNewSubscriptionsData)

const selectorUpsaleBundleData = (state: RootState) => state.data?.properties?.upsale_bundle
export const useUpsaleBundleData = () => useSelector(selectorUpsaleNewSubscriptionsData)

const pricesExist = (data: {
  price?: PriceContentV2_PriceItem
  price_discounted?: PriceContentV2_PriceItem
}) => Boolean(data.price && data.price_discounted)

const isValidUpsaleBlock =
  (state: State) =>
  (block: PaywallBlocks): boolean => {
    if (!block.startsWith("PB_UPSALE")) {
      return false
    }
    if (!selectorIsBlockExist(state, block)) {
      return false
    }
    if (block === PaywallBlocks.PB_UPSALE_SUBSCRIPTION_ON_EXIT) {
      return false
    }

    if (block === PaywallBlocks.PB_UPSALE_SKIP_TRIAL) {
      return Boolean(
        selectorUpsaleSubscriptionPrice(state) && selectorUpsaleSubscriptionDiscountedPrice(state)
      )
    }

    if (block === PaywallBlocks.PB_UPSALE_WORKBOOK) {
      return pricesExist(selectorUpsaleWorkbookData(state))
    }

    if (
      block === PaywallBlocks.PB_UPSALE_AI_SCANNER ||
      block === PaywallBlocks.PB_UPSALE_AI_SCANNER_2
    ) {
      return pricesExist(selectorUpsaleAIScannerData(state))
    }

    if (
      block === PaywallBlocks.PB_UPSALE_AI_SCANNER_3 ||
      block === PaywallBlocks.PB_UPSALE_NEW_SUBSCRIPTIONS
    ) {
      return (selectorUpsaleNewSubscriptionsData(state)?.prices ?? []).length > 0
    }

    if (block === PaywallBlocks.PB_UPSALE_BUNDLE) {
      return (selectorUpsaleBundleData(state)?.prices ?? []).length > 0
    }

    return true
  }

export const useIsPaymentFormInPopup = () =>
  useSelector((state) =>
    Boolean(selectorIsBlockExist(state, PaywallBlocks.PB_PAYMENT_FORM_WITH_INVOICE_3_IN_POPUP))
  )

export const useSummaryList = () => useSelector((state) => state.data?.summary?.list ?? [])
export const useSpecialOfferOpts = () =>
  useSelector(
    (state) =>
      state.data?.properties?.offer_popup_properties ?? {
        text: undefined,
        button_text: undefined,
        display_timeout: 3,
        await_scroll: true,
      }
  )
export const usePriceSectionTitle = () => useSelector((state) => state.data?.price?.title)
export const usePayButtonTitle = () => useSelector((state) => state.data?.price?.button_text)
export const useIsContentLoaded = () => useSelector((state) => Boolean(state.data))

export const useAnalyticsParameters = () =>
  useSelector((state) => state.data?.analytics_event_parameters ?? {})
const selectorBlocksOrder = (state: State) => state.data?.paywall_blocks ?? []
export const useBlocksOrder = () => useSelector(selectorBlocksOrder)
export const useProgram2Data = () =>
  useSelector(
    (state) =>
      state.data?.properties?.program2_properties ?? {
        title: "",
        description: "",
        issues: [],
      }
  )

const selectorUpsaleBlocks = (state: State) => {
  const blocks = selectorBlocksOrder(state)
  const upsaleBlocks = blocks.filter(isValidUpsaleBlock(state))

  return upsaleBlocks
}
export const useUpsaleBlocks = () => useSelector(selectorUpsaleBlocks)

export const useHeader2Data = () =>
  useSelector(
    (state): { title: AttributedStringType | undefined } =>
      state.data?.properties?.header2_properties ?? { title: toAttributedString("") }
  )

export const usePriceSelectData = () =>
  useSelector(
    (state): PriceSelectProperties =>
      state.data?.properties?.price_select_properties ?? {
        button_text: "",
        pre_selector_texts: [],
      }
  )

export const useSelectedPrice = () =>
  useSelector((state): PriceContentV2_PriceItem | undefined =>
    pricesSelector(state).find(({ id }) => id === state.selectedPriceId)
  )

export const usePaymentProvidersPreference = () =>
  useSelector((state) => state.data?.properties?.payment_form_properties?.provider_preference ?? [])

export const useHeaderPrePaywallData = () =>
  useSelector(
    (state): HeaderPrePaywallProperties =>
      state.data?.properties?.header_pre_paywall_properties ?? {
        main_goal_text: "Your skin will be improved in 4 weeks",
        main_goal_img: "",
      }
  )

export const useHeaderGTData = () =>
  useSelector(
    (state): HeaderGTProperties =>
      //todo fixme
      state.data?.properties?.header_gt_properties ?? {
        main_goal_text: "Your skin will be improved in 4 weeks",
        main_goal_img: "",
      }
  )

export const useHeaderGT2Data = () =>
  useSelector(
    (state): HeaderGT2Properties =>
      //todo fixme
      state.data?.properties?.header_gt2_properties ?? {
        main_goal_text: "Your skin will be improved in 4 weeks",
        main_goal_img: "",
      }
  )

export const useHeaderLuvlyData = () =>
  useSelector(
    (state): HeaderGT2Properties =>
      //todo fixme
      state.data?.properties?.header_luvly_properties ?? {
        main_goal_text: "Your skin will be improved in 4 weeks",
        main_goal_img: "",
      }
  )

export const useTimerData = () => {
  const _minutes = Number.parseInt(useBlockProperties()["GLOBAL.timer_minutes"] ?? "", 10)
  return { minutes: Number.isNaN(_minutes) ? 15 : _minutes }
}

export const useWellnessProfileData = () =>
  useSelector(
    (state): WellnessProfile =>
      state.data?.properties?.wellness_profile ?? {
        goal: SkinIssues.SKIN_ISSUE_UNSPECIFIED,
        level: WellnessProfile_WellnessLevel.GOOD,
      }
  )

export const useFaceScanResultIssues = () =>
  useSelector(
    (state): SkinIssues[] => state.data?.properties?.face_scan_result_properties?.issues ?? []
  )
export const useFaceScanResultScan = () =>
  useSelector((state) => state.data?.properties?.face_scan_result_properties?.scan_url)

export const usePaymentFormPopupIsOpen = () =>
  useSelector((state): boolean => state.paymentFormPopup.isOpen)

const selectorPaywallVariantProperties = (state: RootState) =>
  state.data?.variants_properties?.properties ?? {}

const selectorPaywallVariantPropertiesExtended = (state: RootState) =>
  state.data?.variants_properties?.properties_extended ?? {}

export const useBlockProperties = () =>
  useSelector((state): Record<string, string> => selectorPaywallVariantProperties(state))

export const useBlockPropertiesExtended = () =>
  useSelector((state) => selectorPaywallVariantPropertiesExtended(state))

export const useUpsaleSubscriptionOnExitState = () =>
  useSelector((state) => state.subscriptionOnExitFlow)

export const useUpsaleSubscriptionOnExitData = () =>
  useSelector(
    (state) =>
      state.data?.properties?.upsale_subscription_on_exit_properties ?? {
        prices_discounted: [],
        discount_percent: 0,
      }
  )

export const useEnhancedPhotoData = () =>
  useSelector(
    (state) =>
      state.data?.properties?.enhanced_photo_properties ?? {
        status: EnhancedPhotoProperties_EnhancementStatus.ENHANCEMENT_STATUS_UNKNOWN,
        original_photo_url: "https://storage.pora.ai/6e4d34d235ebe6cec99b7965b3cef9f5.webp",
        enhanced_photo_url: "https://storage.pora.ai/d1b44adf0113ad30269d6648ab74e4c6.webp",
      }
  )

export const useAISkinCarePlanProperties = () =>
  useSelector(
    (state) =>
      state.data?.properties?.ai_skincare_plan_properties ?? {
        blocks: [],
      }
  )
