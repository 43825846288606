import { callOnce } from "~/utils/callOnce"
import { getSHA256Hex } from "~/utils/hashString"
import {getCookie} from "~/utils";

type PriceInfo = { priceId: string; currency: string; value: number | string; userEmail: string } // FIXME value must be number
const PORA_PRODUCT = "pora_premium"

const getSnaptr = () => window?.snaptr ?? (() => undefined)

// We need to call initiateCheckout only once per session, otherwise
// algorithm will think that people who open checkout multiple times are 'better'
// but there are not
// So we save information that checkout called once in closure and thats why we use only one instance here
const pixelService = createSnapPixelService(getSnaptr)

export const useSnapPixel = () => {
  return pixelService
}

function createSnapPixelService(getSnaptr: () => typeof window.snaptr) {
  return {
    initiateCheckout: callOnce(async ({ currency, value }: PriceInfo) => {
      getSnaptr()("track", "ADD_CART", {
        price: value,
        currency,
        items_ids: [PORA_PRODUCT],
      })
    }),
    purchase: async ({ currency, value, userEmail }: PriceInfo) => {
      getSnaptr()("track", "PURCHASE", {
        price: value,
        currency,
        transaction_id: await getSHA256Hex(userEmail.toLowerCase() + "|" + "PURCHASE"),
        user_hashed_email: userEmail ? await getSHA256Hex(userEmail.toLowerCase()) : "",
        uuid_c1: getCookie("_scid"),
        items_ids: [PORA_PRODUCT],
      })
    },
  }
}
